import svg0 from './main-0.svg';
import svg1 from './main-1.svg';
import svg2 from './main-2.svg';
import svg3 from './main-3.svg';
import svg4 from './main-4.svg';
import svg5 from './main-5.svg';
import svg6 from './main-6.svg';
import svg7 from './main-7.svg';

const mainCharacterFrames = [
  svg0,
  svg1,
  svg2,
  svg3,
  svg4,
  svg5,
  svg6,
  svg7,
];

export default mainCharacterFrames;
